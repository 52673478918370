<template>
  <div class="page-content">
    <div class="logo" v-push="`/`">
      <img :src="headerIco.Homepage.ico" width="200" />
    </div>

    <div class="login-box">
      <div class="login-box-content columnSC">
        <JLImg class="cart-image" :src="headerIco.cart.ico" :lazy="false"></JLImg>
        <div class="rowCC menu-box" v-if="data.loginMethodType === 'passwordLogin' || data.loginMethodType === 'codeLogin'">
          <div
            v-for="(item, index) in loginMethodList"
            :key="index"
            class="menu-item"
            :class="data.loginMethodType === item.type ? 'active' : ''"
            @click="changeLoginMode(item.type)"
          >
            {{ item.name }}
          </div>
        </div>
        <code-login :loginType="data.loginMethodType" @changeLoginMode="changeLoginMode" />
        <other-login v-if="0"></other-login>
      </div>
    </div>
  </div>
</template>

<script setup>
  import otherLogin from './compontent/other-login'
  import codeLogin from './compontent/code-login'
  import CheckBox from '@/components/CheckBox'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits, watch } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const data = reactive({
    loginMethodType: ''
  })

  const loginMethodList = [
    { type: 'passwordLogin', name: 'Via Password' },
    { type: 'codeLogin', name: 'Via OTP' }
  ]

  const headerIco = {
    Homepage: { ico: 'https://cdn.qingmeidata.com/38c3af48.png' },
    cart: { ico: 'https://cdn.qingmeidata.com/e4207aff.png' }
  }

  const changeBox = (flag) => {
    form.agreeStatus = flag
  }
  // 监听路由变化
  watch(
    () => router.currentRoute.value.query,
    (newValue) => {
      const type = newValue && newValue.type ? newValue.type : 'passwordLogin'
      console.log(type, 'currentRoute')
      changeLoginMode(type)
    }
  )

  // 切换登录类型
  const changeLoginMode = (type) => {
    data.loginMethodType = type ? type : loginMethodList[0].type
    if (type !== 'passwordLogin' && type !== 'codeLogin') {
      return proxy.$router.push({
        path: '/login',
        query: {
          type: type
        }
      })
    }
    // console.log(data.loginMethodType, 'data.loginMethodType')
  }

  const handleSkipType = () => {
    userStore.setAddressList([])
    userStore.setCollectProduct(0)
    userStore.setLoginOut()
  }

  onMounted(() => {
    const type = useRoute().query.type || 'passwordLogin'
    const skipType = useRoute().query.skipType || ''
    console.log(type, 'type')
    handleSkipType()
    changeLoginMode(type)
  })
</script>

<style lang="less" scoped>
  .page-content {
    display: flex;
    position: relative;
    width: 100%;
    height: 100vh;
    background: url('https://cdn.qingmeidata.com/bdaa5ebf.jpg') no-repeat;
    background-size: cover;
    .logo {
      margin: 30px 0 0 30px;
    }
    .login-box {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 450px;
      min-height: 200px;
      .cart-image {
        width: 120px;
        text-align: center;
      }
    }
  }

  .menu-box {
    margin-top: 80px;
    .menu-item {
      position: relative;
      padding: 0 20px;
      font-size: 20px;
      color: rgba(83, 83, 83, 1);
      cursor: pointer;
      &::after {
        visibility: hidden;
        content: '';
        margin-top: 5px;
        display: block;
        border-bottom: 2px solid var(--btnBgColor);
      }
      &:hover {
        color: var(--btnBgColor);
        &::after {
          visibility: inherit;
        }
      }
      &.active {
        color: var(--btnBgColor);
        &::after {
          visibility: inherit;
        }
      }
    }
  }
</style>
